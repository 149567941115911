
@import "./app/common/assets/sass/variable.scss";
@import "~bootstrap/scss/bootstrap";
@import"./app/common/assets/sass/background.scss";
@import"./app/common/assets/sass/common.scss";
@import"./app/common/assets/sass/control.scss";
@import"./app/common/assets/sass/layout.scss";
@import"./app/common/assets/sass/header.scss";
@import"./app/common/assets/sass/aside.scss";
@import"./app/common/assets/sass/table.scss";
@import"./app/common/assets/sass/pagination.scss";
@import "./app/common/assets/sass/_nav.scss";

//control
@import "./app/common/controls/form/checkbox.scss";
@import "./app/common/controls/form/switch.scss";

